import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";

import * as coinsAnimation from "../../../../animations/coins.json";
import * as starsAnimation from "../../../../animations/stars.json";

import cl from "./defaultAnimationBlock.module.scss";

const DefaultAnimationBlock = ({
  animate,
  setAnimate,
  resetActualAnimation,
}) => {
  const [playCoins, setPlayCoins] = useState(false);
  const [playStars, setPlayStars] = useState(false);

  const lottieOptionsCoins = {
    loop: false,
    autoplay: playCoins,
    animationData: coinsAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const lottieOptionsStars = {
    loop: false,
    autoplay: playStars,
    animationData: starsAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (animate) {
      setPlayCoins(true);
      setPlayStars(true);
      const resetTimeout = setTimeout(() => {
        setAnimate(false);
        setPlayStars(false);
        setPlayCoins(false);
        resetActualAnimation();
      }, 2500);

      return () => {
        clearTimeout(resetTimeout);
      };
    }
  }, [animate, setAnimate]);

  return (
    <div className={cl.block}>
      <div className={cl.coins}>
        <Lottie
          key={playCoins ? "play" : "stop"}
          options={lottieOptionsCoins}
          height={80}
          width={80}
        />
      </div>
      <div className={cl.stars}>
        <Lottie
          key={playStars ? "play" : "stop"}
          options={lottieOptionsStars}
          height={80}
          width={80}
        />
      </div>
    </div>
  );
};

export default DefaultAnimationBlock;
