import { useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { observer } from "mobx-react-lite";
import MotionNumber from "motion-number";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "sonner";

import Header from "../../components/header/header";
import Layout from "../../components/layout/layout";
import Actions from "../../components/main/actions/actions";
import Bet from "../../components/main/bet/bet";
import Coin from "../../components/main/coin/coin";
import SliderResult from "../../components/main/sliderResult/sliderResult";
import UsdAmount from "../../components/main/usdAmount/usdAmount";
import { useFaq } from "../../hooks/useFaq.hook";
import { useGame } from "../../hooks/useGame.hook";
import { useLives } from "../../hooks/useLives.hook";
import { useTelegram } from "../../hooks/useTelegram.hook";
import { useUser } from "../../hooks/useUser";
import { eng } from "../../language/eng.language";
import { ru } from "../../language/ru.language";
import GameStore from "../../store/GameStore";

import cl from "./main.module.scss";

const MainPage = observer(() => {
  const { id, username } = useTelegram();
  const { updateFaq } = useFaq();
  const { start, play } = useGame(id);
  const { updateLives } = useLives(id);

  const [language, setLanguage] = useState();
  const { user, userLoading, adCreate, updateLanguage } = useUser(id, username);
  const [bet, setBet] = useState("");
  const resultGame = GameStore.resultGame;
  let btnDisabled = useRef(false);
  const [sliderAnimation, setSliderAnimation] = useState(true);
  const [amount, setAmount] = useState(user?.amount || 0);
  const navigate = useNavigate();
  const [coinAnimation, setCoinAnimation] = useState(false);

  const [clicker, setClicker] = useState(false);
  const [stars, setStars] = useState(false);
  const [lives, setLives] = useState(user?.live);
  const [maxLives, setMaxLives] = useState(1500);
  const [statusCoin, setStatusCoin] = useState(0);
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const asd = () => {
    GameStore.setResultGame({
      win: 1,
      animation: "FALLING_COINS",
      // animation: "DEFAULT",
      // animation: "X2",
      bet: 200,
      amountChange: 100,
    });

    // setResultGame({ win: true, animation: "X2" });
    // setResultGame({ win: true, animation: "FALLING_COINS" });
  };

  useEffect(() => {
    setLanguage(user?.language === "ru" ? ru : eng);
  }, [user]);

  useEffect(() => {
    if (id === 0) {
      window.location.href = "https://t.me/nvuttibot";
    }
  }, [id]);

  useEffect(() => {
    const adId = parseInt(searchParams.get("ad"));
    if (adId !== NaN) {
      adCreate(adId, id);
    }
  }, [searchParams]);

  useEffect(() => {
    if (user?.faq === false && userLoading === false) {
      updateFaq(id);
      navigate("/faq");
    }
  }, [user, userLoading]);

  useEffect(() => {
    GameStore.setResultGame(resultGame);
    unblockGameBtnsAfterAnimation();
  }, [resultGame]);

  useEffect(() => {
    setTimeout(() => {
      setAmount(user?.amount);
      setLives(Math.ceil(user?.live));
    }, 2000);
    return clearTimeout;
  }, [user]);

  const returnStartBtnState = () => {
    setSliderAnimation(false);
    btnDisabled.current = false;
  };

  const unblockGameBtnsAfterAnimation = () => {
    setSliderAnimation(true);
    //в зависимости от времени анимации которая будет проигрываться, ставим в таймаут
    //время после которого кнопки снова станут кликабельными
    switch (resultGame?.animation) {
      case "DEFAULT":
        setTimeout(function () {
          returnStartBtnState();
        }, 2000);
        break;
      case "X2":
        setTimeout(function () {
          returnStartBtnState();
        }, 4500);
        break;
      default:
        break;
    }

    return clearTimeout;
  };

  const send = async (value, e) => {
    e.preventDefault();

    if (btnDisabled.current) return;
    btnDisabled.current = true;
    if (bet < 100 || bet === "") {
      toast.error(language?.minBet);
      btnDisabled.current = false;
      return;
    }
    if (user.amount < bet) {
      toast.error(language?.enoughTokens);
      btnDisabled.current = false;
      return;
    }

    const data = await play({
      assumption: value,
      id,
      bet,
    });
    GameStore.setResultGame(data);
  };

  useEffect(() => {
    if (coinAnimation) {
      setTimeout(() => {
        setCoinAnimation(false);
      }, 1000);
    }
  }, [coinAnimation]);

  const livesRef = useRef(lives); // Ссылка на текущее состояние жизней

  useEffect(() => {
    livesRef.current = lives;
  }, [lives, clicker]);

  useEffect(() => {
    const lifeInterval = setInterval(() => {
      if (livesRef.current < maxLives) {
        setLives((prevLives) => Math.min(prevLives + 1, maxLives));
      }
    }, 2000);

    return () => clearInterval(lifeInterval);
  }, [maxLives]);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["user"] });
    setCircle([]);
    setNumbers([]);
  }, [clicker, stars]);

  const [circle, setCircle] = useState([]);
  const [numbers, setNumbers] = useState([]);
  useEffect(() => {
    if (circle.length > 100) {
      setCircle([1, 1, 1]);
    }
  }, [circle]);

  const check = useCallback(
    debounce((amount) => {
      updateLives({ id, amount, live: livesRef.current });
    }, 1000),
    [],
  );

  return (
    <Layout>
      <Header />
      <img className={cl.cover} src="/image/backgrounds/main.png" alt="" />

      <div className={cl.body}>
        {!stars && (
          <button onClick={() => setClicker(!clicker)} className={cl.changeBtn}>
            {clicker ? (
              <picture>
                <source
                  type="image/png"
                  srcSet="/image/main/game.png 1x, /image/main/game2x.png 2x "
                />
                <img src={"/image/main/game.png"} alt="" />
              </picture>
            ) : (
              <img src={"/image/main/clicker.svg"} alt="" />
            )}
          </button>
        )}
        <button className={cl.stars} onClick={() => setStars(!stars)}>
          {stars ? (
            <div>{`<-${language?.starsBack}`}</div>
          ) : (
            <img src="/image/main/star.svg" alt="star" />
          )}
        </button>
        {stars ? (
          <>
            <div className={cl.image}>
              <img src={"/image/main/bigStar.svg"} alt="star" />
            </div>
            <div className={cl.title}>
              {language?.starsTitle}
              <br />
              <span>{user?.stars}</span> {language?.stars}
            </div>
            <div className={cl.info}>
              <img src="/image/main/hamster.svg" alt="hamster" />
              <div className={cl.infoText}>{language?.starsText}</div>
            </div>
            <div className={cl.subTitle}>{language?.starsBonus}</div>
            <div className={cl.text}>{language?.starsNoTask}</div>
          </>
        ) : clicker ? (
          <>
            <div
              className={cl.coin}
              onClick={() => {
                setLives((prev) => Math.max(prev - 1, 0));
                livesRef.current > 0 && setAmount((prev) => prev + 1);
                check(amount + 1);
                setCircle((prev) => [...prev, 1]);
                let positionX = Math.floor(Math.random() * 101);
                let positionY = Math.floor(Math.random() * 101);
                let size = Math.floor(Math.random() * (30 - 20 + 1) + 20);
                setNumbers((prev) => [
                  ...prev,
                  { x: positionX, y: positionY, size },
                ]);
              }}
              onTouchStart={() => setStatusCoin(1)}
              onTouchEnd={() => setStatusCoin(0)}
            >
              <div className={cl.coinBody}>
                <img
                  src="/image/main/coin.svg"
                  className={statusCoin ? cl.coinPress : cl.coinUnpress}
                  alt="coin"
                />
                {circle.map((number, index) => (
                  <div key={index} className={cl.click}></div>
                ))}
                {numbers.map((number, index) => (
                  <div
                    key={index}
                    className={cl.number}
                    style={{
                      "--left": `${number.x}%`,
                      "--top": `${number.y}%`,
                      fontSize: `${number.size}px`,
                    }}
                  >
                    +1
                  </div>
                ))}
              </div>
            </div>
            <div className={cl.amount}>{amount} NVT</div>

            <div className={cl.progressBar}>
              <div className={cl.progressBarValue}>
                <svg
                  width="10"
                  height="14"
                  viewBox="0 0 10 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.02577 2.01627L1.618 6.60938H3.20777C4.02051 6.60938 4.47177 7.34283 4.47177 7.96838V11.4845L7.87953 6.89138H6.28977C5.47702 6.89138 5.02577 6.15793 5.02577 5.53238V2.01627ZM0.246167 5.94387C0.255376 5.92952 0.265133 5.91539 0.275441 5.9015L4.27438 0.511584C4.2744 0.511555 4.27442 0.511526 4.27444 0.511497C4.63986 0.0189022 5.24202 -0.121332 5.74978 0.105707C6.22947 0.320193 6.52577 0.810867 6.52577 1.36038V5.39138H8.23377C8.85073 5.39138 9.24775 5.82622 9.40268 6.23197C9.55879 6.64083 9.54131 7.16962 9.22178 7.59968L5.22316 12.9892C5.22313 12.9892 5.22311 12.9892 5.22309 12.9893C4.85767 13.4819 4.25551 13.6221 3.74775 13.3951C3.26806 13.1806 2.97177 12.6899 2.97177 12.1404V8.10938H1.26377C0.646494 8.10938 0.250047 7.67359 0.0955876 7.26841C-0.0550579 6.87324 -0.0437351 6.36581 0.246167 5.94387Z"
                    fill="#6A6F83"
                  />
                </svg>
                <span> {maxLives} / </span>
                <span className={cl.progressBarValueActive}>{lives || 0}</span>
              </div>
              <div
                className={cl.progressBarScale}
                style={{ "--procent": (100 * livesRef?.current) / 1500 }}
              ></div>
            </div>
          </>
        ) : (
          <>
            <Coin />
            <div className={cl.amount}>
              <MotionNumber value={amount || 0} locales="en-US" /> NVT
              <UsdAmount nvtAmount={amount} />
            </div>
            <Bet
              bet={bet || 0}
              setBet={setBet}
              amount={user?.amount}
              min={language?.min}
              max={language?.max}
              placeholder={language?.bet}
            />
            <SliderResult
              number={start}
              result={resultGame?.resultNumber}
              animationPlay={sliderAnimation}
              prevNumber={resultGame?.game}
            />

            <Actions
              less={language?.less}
              more={language?.more}
              start={start}
              send={send}
              disabled={btnDisabled.current}
            />
          </>
        )}
      </div>
    </Layout>
  );
});

export default MainPage;
