import React from "react";
import useSound from "use-sound";

import clickSound from "../../sounds/click.mp3";

import cl from "./confirm.module.scss";

const ConfirmPopup = ({ func, visible, setVisible, title, language }) => {
  const [play] = useSound(clickSound);
  return (
    <div
      className={
        visible ? [cl.wrapper, cl.wrapperActive].join(" ") : cl.wrapper
      }
      onClick={() => setVisible(false)}
    >
      <div className={cl.body} onClick={(e) => e.stopPropagation()}>
        <div className={cl.title}>{title}</div>
        <div className={cl.row}>
          <button
            className={cl.confirm}
            onClick={() => {
              func();
              play();
              setVisible(false);
            }}
          >
            {(language || "ru") === "ru" ? "Да" : "Yes"}
          </button>
          <button
            className={cl.notconfirm}
            onClick={() => {
              play();
              setVisible(false);
            }}
          >
            {(language || "ru") === "ru" ? "Нет" : "No"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
