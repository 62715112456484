import { clearTimers, observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import useSound from "use-sound";

import bonusGameSound from "../../../sounds/bonusGameSound.mp3";
import loseSound from "../../../sounds/lose.mp3";
import winSound from "../../../sounds/win.mp3";
import GameStore from "../../../store/GameStore";
import DefaultAnimationBlock from "../animationEvents/defaultAnimationBlock/defaultAnimationBlock";
import FallingCoinsAnimationBlock from "../animationEvents/fallingCoinsAnimationBlock/fallingCoinsAnimationBlock";
import X2AnimationBlock from "../animationEvents/x2AnimationBlock/x2AnimationBlock";

const AnimationsBlock = observer(
  ({ mainCoinScalingUpWithCoins, scaleDownAndUpMainCoin }) => {
    const resultGame = GameStore.resultGame;
    const [x2Animation, setx2Animation] = useState(false);
    const [defaultAnimation, setDefaultAnimation] = useState(false);
    const [fallingCoinsAnimation, setFallingCoinsAnimation] = useState(false);
    const [actualAnimation, setActualAnimation] = useState("");
    const [playFallingCoinsSound] = useSound(bonusGameSound);

    const [isFirstLoad, setIsFistLoad] = useState(true);

    const win = useSound(winSound);
    const lose = useSound(loseSound);

    const resetActualAnimation = () => {
      setActualAnimation("");
    };

    useEffect(() => {
      setTimeout(() => {
        if (resultGame?.win && resultGame?.animation === "DEFAULT") {
          win[0]();
        } else if (!resultGame?.win) {
          lose[0]();
        }
        if (!isFirstLoad) {
          if (!resultGame?.win) {
            mainCoinScalingUpWithCoins();
          }
          if (resultGame?.win) {
            switch (resultGame?.animation) {
              case "DEFAULT":
                setActualAnimation("DEFAULT");
                setDefaultAnimation(true);
                mainCoinScalingUpWithCoins();

                break;
              case "X2":
                setActualAnimation("X2");
                setx2Animation(true);
                scaleDownAndUpMainCoin(500, 2500);
                setTimeout(() => {
                  mainCoinScalingUpWithCoins();
                }, 3000);
                break;
              case "FALLING_COINS":
                playFallingCoinsSound();
                setFallingCoinsAnimation(true);
                scaleDownAndUpMainCoin(0, 2500);
                setActualAnimation("FALLING_COINS");
                setTimeout(() => {
                  GameStore.setFallingCoinGameActive(true);
                }, 800);

                break;
              default:
                break;
            }
          }
        } else {
          setIsFistLoad(false);
        }
      }, 1300);
      return clearTimers;
    }, [resultGame]);
    return (
      <div>
        <div
          style={{
            opacity: actualAnimation === "DEFAULT" ? 1 : 0,
            pointerEvents: actualAnimation === "DEFAULT" ? "all" : "none",
            transition: "opacity 0.3s ease",
          }}
        >
          <DefaultAnimationBlock
            animate={defaultAnimation}
            setAnimate={setDefaultAnimation}
            resetActualAnimation={resetActualAnimation}
          />
        </div>
        <div
          style={{
            opacity: actualAnimation === "X2" ? 1 : 0,
            pointerEvents: actualAnimation === "X2" ? "all" : "none",
            transition: "opacity 0.3s ease",
          }}
        >
          <X2AnimationBlock
            animate={x2Animation}
            setAnimate={setx2Animation}
            resetActualAnimation={resetActualAnimation}
          />
        </div>
        <div
          style={{
            opacity: actualAnimation === "FALLING_COINS" ? 1 : 0,
            pointerEvents: actualAnimation === "FALLING_COINS" ? "all" : "none",
            transition: "opacity 0.3s ease",
          }}
        >
          <FallingCoinsAnimationBlock
            animate={fallingCoinsAnimation}
            setAnimate={setFallingCoinsAnimation}
            resetActualAnimation={resetActualAnimation}
          />
        </div>
      </div>
    );
  },
);
export default AnimationsBlock;
