import React, { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie";
import useSound from "use-sound";

import * as animationFire from "../../../animations/fire.json";
import fireSound from "../../../sounds/fire.mp3";
import spinSound from "../../../sounds/spin.mp3";

import cl from "./sliderResult.module.scss";

const SliderResult = ({ number, result, animationPlay, prevNumber }) => {
  const [isTransition, setIsTransition] = useState(false);
  const refSlider = useRef(null);
  const refToggle = useRef(null);
  const refStart = useRef(null);

  const spin = useSound(spinSound);
  const fire = useSound(fireSound);

  useEffect(() => {
    if (animationPlay) {
      spin[0]();
    }
  }, [animationPlay]);

  useEffect(() => {
    if (isTransition) {
      fire[0]();
    }
  }, [isTransition]);
  useEffect(() => {
    const sliderWidth = refSlider.current.offsetWidth;
    const sliderStartWidth = refStart.current.offsetWidth;
    const left = (number * (sliderWidth - sliderStartWidth - 5)) / 100;
    refStart.current.style.left = `${left}px`;
  }, [number]);

  useEffect(() => {
    const sliderWidth = refSlider.current.offsetWidth;
    const sliderToggleWidth = refToggle.current.offsetWidth;
    let left = (result * (sliderWidth - sliderToggleWidth - 5)) / 100;
    if (!animationPlay) {
      left = 1;
    }
    refToggle.current.style.left = `${left || 1}px`;
  }, [result, animationPlay]);

  useEffect(() => {
    setIsTransition(true);
    setTimeout(function () {
      setIsTransition(false);
    }, 500);
  }, [number]);
  const fireAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationFire,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={cl.block}>
      <div className={cl.scale} ref={refSlider}>
        <div className={cl.toggle} ref={refToggle}>
          {result && animationPlay ? result : 0}
        </div>
        <div className={cl.start} ref={refStart}>
          <div className={cl.start__content}>{number}</div>
          <div
            className={[
              cl.fire,
              prevNumber > number ? cl.fire__right : cl.fire__left,
              isTransition && cl.fire__active,
            ].join(" ")}
          >
            <Lottie
              options={fireAnimationOptions}
              height={52}
              width={52}
              speed={1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderResult;
