import React, { useEffect, useState } from "react";

import Bonus from "../../components/main/bonus/bonus";
import Wallet from "../../components/main/wallet/wallet";
import { useTelegram } from "../../hooks/useTelegram.hook";
import { useUser } from "../../hooks/useUser";
import { eng } from "../../language/eng.language";
import { ru } from "../../language/ru.language";

import cl from "./header.module.scss";

const Header = ({}) => {
  const [language, setLanguage] = useState();
  const { id, username } = useTelegram();
  const { user, updateLanguage } = useUser(id, username);

  useEffect(() => {
    setLanguage(user?.language === "ru" ? ru : eng);
  }, [user]);
  return (
    <div className={cl.row}>
      <Bonus title={language?.bonus} active={user?.bonus} />
      <div
        className={[
          cl.language,
          user?.language === "ru" ? cl.languageLeft : cl.languageRight,
        ].join(" ")}
        onClick={() => {
          updateLanguage({
            language: user?.language === "ru" ? "eng" : "ru",
          });
        }}
      >
        {user?.language === "ru" ? "RUS" : "ENG"}
      </div>
      <Wallet
        title={language?.wallet}
        connectTitle={language?.connectWallet}
        disconnectTitle={language?.disconnectWallet}
        wallet={user?.wallet}
        id={id}
        language={user?.language}
      />
    </div>
  );
};
export default Header;
