import { observer } from "mobx-react-lite";
import React, { useRef } from "react";

import FallingCoinsGame from "../../components/main/fallingCoinsGame/fallingCoinsGame";
import Navbar from "../navbar/navbar";

import cl from "./layout.module.scss";

const Layout = observer(({ children }) => {
  const layoutBodyRef = useRef();
  return (
    <div className={cl.block}>
      <div ref={layoutBodyRef} className={cl.body}>
        {children}
      </div>
      <FallingCoinsGame body={layoutBodyRef} />
      <Navbar />
    </div>
  );
});

export default Layout;
