import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import React, { useEffect, useState } from "react";
import useSound from "use-sound";

import winX2 from "../../../../sounds/win2x.mp3";

import cl from "./x2AnimationBlock.module.scss";

const X2AnimationBlock = ({
  animate,
  setAnimate,
  resetActualAnimation,
  children,
}) => {
  const [playExplosion, setPlayExplosion] = useState(false);
  const [playX2, setPlayX2] = useState(false);
  const [playSound] = useSound(winX2);
  const [x2Animation, setx2Animation] = useState(false);

  useEffect(() => {
    if (animate) {
      playSound();
      setPlayExplosion(true);
      const timeoutX2 = setTimeout(() => {
        setPlayX2(true);
        setx2Animation(true);
      }, 400);

      const resetTimeout = setTimeout(() => {
        setAnimate(false);
        setPlayExplosion(false);
        setPlayX2(false);
        setx2Animation(false);
        resetActualAnimation();
      }, 2500);

      return () => {
        clearTimeout(timeoutX2);
        clearTimeout(resetTimeout);
      };
    }
  }, [animate, setAnimate]);

  return (
    <div className={cl.block}>
      <div className={[cl.x2, cl.lottie].join(" ")}>
        <DotLottieReact
          key={playX2 ? "x2-play" : "x2-stop"}
          src="animations/x2Coins.json"
          autoplay={playX2}
          loop={false}
        />
      </div>

      <div className={[cl.lottie, cl.mixBlend].join(" ")}>
        <DotLottieReact
          key={playExplosion ? "explosion-play" : "explosion-stop"}
          speed={1.6}
          src="animations/explosion.json"
          autoplay={playExplosion}
          loop={false}
        />
      </div>
    </div>
  );
};

export default X2AnimationBlock;
