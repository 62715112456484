import { useEffect, useRef, useState } from "react";

import GameStore from "../../../../store/GameStore";

import cl from "./timer.module.scss";

const Timer = ({ maxSeconds, active, onEnd }) => {
  const [timer, setTimer] = useState(0);
  const timerSpan = useRef();
  const startTime = useRef();

  const formatTimer = (time) => {
    const seconds = Math.floor(time / 1000);
    const milliseconds = Math.floor((time - seconds * 1000) / 10);
    return `${
      seconds < 10 ? `0${seconds}` : seconds
    }:${milliseconds < 10 ? `0${milliseconds}` : milliseconds}`;
  };
  useEffect(() => {
    if (!active) return;
    if (!startTime.current) {
      startTime.current = Date.now();
    }
    setTimeout(() => {
      setTimer((prev) => {
        if (prev <= maxSeconds * 1000) return Date.now() - startTime.current;
        return maxSeconds * 1000;
      });
      if (timer >= maxSeconds * 1000) {
        GameStore.setFallingCoinGameActive(false);
        setTimer(0);
        startTime.current = null;
        onEnd();
      } else {
        timerSpan.current.style.width = `${(timer / (maxSeconds * 1000)) * 100}%`;
      }
    }, 50);
  }, [active, timer]);

  return (
    <div className={cl.wrapper}>
      <p className={cl.text}>{formatTimer(timer)}</p>
      <div className={cl.timer}>
        <span ref={timerSpan} />
      </div>
    </div>
  );
};
export default Timer;
