import { useEffect, useState } from "react";

import { $auth } from "../../../config/api.config";

import cl from "./usdAmount.module.scss";

const UsdAmount = ({ nvtAmount }) => {
  const [usdAmount, setUsdAmount] = useState(0);
  const [USDToNVT, setUSDToNVT] = useState(0);
  useEffect(() => {
    const totalUSD = USDToNVT * nvtAmount;

    if (totalUSD) setUsdAmount(+totalUSD.toFixed(2));
  }, [nvtAmount, USDToNVT]);
  useEffect(() => {
    $auth.get("/token/info").then((data) => {
      setUSDToNVT(data.data.priceInUSD);
    });
  }, []);
  return <div className={cl.usd}>~{usdAmount}$</div>;
};
export default UsdAmount;
