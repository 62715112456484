import { makeAutoObservable } from "mobx";

class GameStore {
  isFallingCoinGameActive = false;
  resultGame = {};
  constructor() {
    makeAutoObservable(this);
  }

  setResultGame(resultGame) {
    this.resultGame = resultGame;
  }
  setFallingCoinGameActive(value) {
    this.isFallingCoinGameActive = value;
  }
  plusAmounChange(value) {
    this.resultGame = {
      ...this.resultGame,
      amountChange: this.resultGame.amountChange + value,
    };
  }
  setAnimation(value) {
    this.resultGame = { ...this.resultGame, animation: value };
  }
}

export default new GameStore();
