import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";

import * as bonusTextAnimation from "../../../../animations/bonusGameText.json";

import cl from "./fallingCoinsAnimationBlock.module.scss";

const FallingCoinsAnimationBlock = ({
  animate,
  setAnimate,
  resetActualAnimation,
}) => {
  const [playAnim, setPlayAnim] = useState(false);

  const lottieOptions = {
    loop: false,
    autoplay: playAnim,
    animationData: bonusTextAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (animate) {
      setPlayAnim(true);
      const resetTimeout = setTimeout(() => {
        setAnimate(false);
        setPlayAnim(false);
        resetActualAnimation();
      }, 2500);

      return () => {
        clearTimeout(resetTimeout);
      };
    }
  }, [animate, setAnimate]);

  return (
    <div className={cl.block}>
      <div className={cl.bonusText}>
        <Lottie
          key={playAnim ? "play" : "stop"}
          options={lottieOptions}
          height={200}
          width={200}
        />
      </div>
    </div>
  );
};

export default FallingCoinsAnimationBlock;
