import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";

import * as bigFallingCoin from "../../../animations/bigFallingCoin.json";
import * as smallFallingCoin from "../../../animations/smallFallingCoin.json";
// import gameService from "../../../services/game.service";
import GameStore from "../../../store/GameStore";

import FallingCoin from "./fallingCoin/fallingCoin";
import cl from "./fallingCoinsGame.module.scss";
import Timer from "./timer/timer";

const BIG_COIN_SIZE = 60;
const SMALL_COIN_SIZE = 40;
const MAX_GAME_SECONDS = 10;
const MAX_COINS_COUNT = 30;
const SMALL_COIN_COST = 50;
const BIG_COIN_COST = 100;

const FallingCoinsGame = observer(({ body }) => {
  const active = GameStore.isFallingCoinGameActive;
  // const active = true;

  const coinsCount = useRef({ small: 0, big: 0 });
  const fallingCoinBlockRef = useRef();
  const [fallingCoins, setFallingCoins] = useState([]);

  const randomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const generateCoins = () => {
    const fullWidth = fallingCoinBlockRef.current.clientWidth;

    let lastY = -100;

    return Array.from({ length: MAX_COINS_COUNT }, (_, index) => {
      const size = index % 2 === 0 ? BIG_COIN_SIZE : SMALL_COIN_SIZE;
      const coinType = index % 2 === 0 ? "BIG" : "SMALL";
      const fallingTime = randomNumber(14, 15);
      const x = Math.floor(Math.random() * ((fullWidth - size) / size) * size);
      const y = lastY + Math.floor(Math.random() * 30);

      lastY = y - size;
      return {
        id: index + 1,
        x,
        y,
        size,
        fallingTime,
        coinType,
        coinPrice: coinType === "SMALL" ? SMALL_COIN_COST : BIG_COIN_COST,
      };
    });
  };

  useEffect(() => {
    body.current.style.overflow = "auto";
    if (active) {
      body.current.scrollTo({
        top: 0,
      });
      body.current.style.overflow = "hidden";
      const generatedCoins = generateCoins();
      setFallingCoins(generatedCoins);
    } else {
      setFallingCoins([]);
    }
  }, [active]);

  return (
    <div className={[cl.wrapper, active && cl.gameActive].join(" ")}>
      <div className={cl.body}>
        <Timer
          maxSeconds={MAX_GAME_SECONDS}
          active={active}
          onEnd={async () => {
            const totalCost =
              coinsCount.current.small * SMALL_COIN_COST +
              coinsCount.current.big * BIG_COIN_COST;

            GameStore.plusAmounChange(totalCost);
            GameStore.setAnimation("DEFAULT");
            coinsCount.current = { small: 0, big: 0 };

            setFallingCoins([]);
          }}
        />

        <div ref={fallingCoinBlockRef} className={cl.fallingCoinsBlock}>
          {fallingCoins.map((fallingCoin) => (
            <FallingCoin
              key={fallingCoin.id}
              left={fallingCoin.x}
              size={fallingCoin.size}
              y={fallingCoin.y}
              delay={fallingCoin.id * 100}
              fallingTime={fallingCoin.fallingTime}
              coinType={fallingCoin.coinType}
              animationData={
                fallingCoin.coinType === "SMALL"
                  ? smallFallingCoin
                  : bigFallingCoin
              }
              coinPrice={fallingCoin.coinPrice}
              onClick={() => {
                if (fallingCoin.coinType === "SMALL") {
                  coinsCount.current.small += 1;
                } else if (fallingCoin.coinType === "BIG") {
                  coinsCount.current.big += 1;
                }
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

export default FallingCoinsGame;
