import { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie";

import cl from "./fallingCoin.module.scss";

const FallingCoin = ({
  left,
  y,
  size,
  fallingTime,
  onClick,
  coinPrice,
  coinType,
  animationData,
  delay = 0,
}) => {
  const coinRef = useRef();
  const bonusTextRef = useRef();
  const [falling, setFalling] = useState(false);
  const [bonusText, setBonusText] = useState(null);
  const [playAnimation, setPlayAnimation] = useState(false);

  const animationOptions = {
    loop: true,
    autoplay: playAnimation,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const init = () => {
    coinRef.current.style.left = `${left}px`;
    coinRef.current.style.top = `${y}px`;
    coinRef.current.style.width = `${size}px`;
    coinRef.current.style.height = `${size}px`;
    coinRef.current.style.transition = `transform ${fallingTime}s linear`;
    setTimeout(() => {
      setFalling(true);
    }, 100);

    setTimeout(() => {
      setPlayAnimation(true);
    }, delay);
  };

  const destroyCoinOnClick = () => {
    const x = coinRef.current.offsetLeft;
    const y = coinRef.current.getBoundingClientRect().top;

    coinRef.current.style.display = "none";
    setBonusText({ x, y, value: `+${coinPrice}` });
    onClick();
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <div
        ref={coinRef}
        className={[cl.coin, falling && cl.falling].join(" ")}
        onClick={destroyCoinOnClick}
      >
        <Lottie
          key={playAnimation ? "play" : "stop"}
          options={animationOptions}
          width={size}
          height={size}
          speed={1}
        />
      </div>
      {bonusText && (
        <div
          ref={bonusTextRef}
          className={cl.bonusText}
          style={{
            left: `${bonusText.x}px`,
            top: `${bonusText.y}px`,
          }}
        >
          {bonusText.value}
        </div>
      )}
    </>
  );
};

export default FallingCoin;
